import React, { useState } from 'react'
import { Row, Col, ListGroupItem } from 'reactstrap'
import {
  FaEnvelope,
  FaEnvelopeOpen,
  FaArchive,
  FaThumbtack,
} from 'react-icons/fa'
import moment from 'moment'
import { markAsReadOrArchivedUserReminders } from '../../redux/actions/userReminder'
import { useDispatch } from 'react-redux'
import { getIconByType } from './utils/navbarDropdownItemUtils'

const NavbarDropdownItem = ({
  isRead,
  onArchive,
  onMarkAsRead,
  markedAsRead,
  isDashboard,
  notification,
  isPreview,
}) => {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)

  const { message, created_at, _id, type, isArchived, isMultiple } =
    notification
  const shouldMuteText = isRead || isArchived?.state
  const textStyle = shouldMuteText ? 'text-muted' : 'fw-bold'
  const createdAtMoment = moment(created_at)
  const currentTime = moment()
  const isWithinEightHours =
    isMultiple && currentTime.diff(createdAtMoment, 'hours') < 8
  const muted = isRead || markedAsRead?.some((item) => item === _id)

  const handleClickIsRead = () => {
    if (!isRead && !isMultiple) {
      if (isDashboard) {
        dispatch(
          markAsReadOrArchivedUserReminders(
            { userReminderIds: [_id] },
            'isRead'
          )
        )
      } else {
        onMarkAsRead(_id)
      }
    }
  }

  const handleClickarchived = () => {
    if (!isMultiple) onArchive(_id)
  }

  return (
    <ListGroupItem
      style={{
        opacity: isArchived?.state ? 0.5 : 1,
        maxWidth: isDashboard ? '100%' : '340px',
        backgroundColor: isWithinEightHours
          ? 'rgba(66, 164, 255, 0.4)'
          : isArchived?.state && isRead
          ? 'rgba(93, 102, 117, 0.15)'
          : 'transparent',
        border: isPreview ? '1px solid lightgray' : 'none',
        width: isPreview ? '100%' : 'auto',
        borderRadius: isPreview ? '4px' : '0px',
        borderBottom: isPreview
          ? '1px solid lightgray'
          : '1px solid var(--bs-list-group-border-color)',
      }}
    >
      <Row onClick={handleClickIsRead} className="align-items-center g-0 p-1">
        <Col xs={2}>{getIconByType(type)}</Col>

        <Col
          xs={8}
          onClick={(e) => {
            if (!e.target.closest('.view-more-link')) {
              setIsExpanded(!isExpanded)
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <div
            className={`small mt-1 ${textStyle}`}
            style={{
              display: 'block',
              maxWidth: '100%',
              overflow: isExpanded ? 'visible' : 'hidden',
              whiteSpace: isExpanded ? 'normal' : 'nowrap',
              textOverflow: isExpanded ? 'clip' : 'ellipsis',
              WebkitLineClamp: isExpanded ? 'none' : 2,
            }}
          >
            {message}
            {notification?.extraData?.link && (
              <>
                {' '}
                <a
                  href={notification?.extraData?.link}
                  className="view-more-link"
                  onClick={(e) => e.stopPropagation()}
                >
                  Ver mas...
                </a>
              </>
            )}
          </div>
          <div className={`small mt-1 ${textStyle}`}>
            {moment(created_at).format('DD MMM')}
          </div>
        </Col>
        <Col xs={2} className="text-end">
          {isDashboard && !isArchived?.state && isRead ? (
            <FaArchive
              title="archivar"
              className="text-muted"
              style={{ cursor: 'pointer' }}
              onClick={handleClickarchived}
            />
          ) : muted ? (
            <FaEnvelopeOpen title="leído" className="text-muted" />
          ) : !isMultiple ? (
            <FaEnvelope title="no leído" />
          ) : (
            isMultiple && <FaThumbtack title="mensaje general" />
          )}
        </Col>
      </Row>
    </ListGroupItem>
  )
}

export default NavbarDropdownItem
