import React, { useCallback } from 'react'
import AsyncSelect from 'react-select/async'
import { baseAPI } from '../../config'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const UserSearch = ({
  className,
  onSubmit,
  defaultValue = null,
  isDisabled = false,
  isMulti = false,
  filters = {},
  type,
  company,
  fetchForUsers,
  showScore,
  judicialParty,
  declinedSubstitutes = [],
  role,
  creatorId,
}) => {
  const location = useLocation()
  const currentPath = location.pathname

  const documentType = currentPath.includes('expedient')
    ? 'expedient'
    : currentPath.includes('procura')
    ? 'procura'
    : null

  const substituteId = useSelector((state) => {
    switch (documentType) {
      case 'expedient':
        return state.expedient?.expedient?.substitute_id?._id
      case 'procura':
        return state.procura?.procura?.substitute_id?._id
      default:
        return null
    }
  })

  const loadUsers = useCallback(
    async (inputValue) => {
      const allFilters = {
        q: inputValue || '',
        ...filters,
        company: company !== undefined ? company : '',
        type: type || '',
        role: role || '',
        judicial_party: judicialParty || '',
        creator_id: creatorId || '',
        blocked: false,
      }

      const queryParams = new URLSearchParams(allFilters)

      const url = `${baseAPI}/${
        fetchForUsers ? 'users' : 'users/users-search'
      }?${queryParams.toString()}`

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        })

        const data = await response.json()

        const usersData = fetchForUsers ? data.docs : data

        if (!usersData || !Array.isArray(usersData)) {
          throw new Error('Invalid users data structure')
        }

        return usersData
          .filter((user) => !declinedSubstitutes.includes(user._id))
          .filter((user) => user._id !== String(substituteId))
          .map((user) => ({
            label: `${user.name} ${user.last_name} - ${user.email}  ${
              showScore ? `- ${user.substitute_score}` : ''
            }`,
            value: user._id,
          }))
      } catch (error) {
        console.error('Error fetching users:', error)
        return []
      }
    },
    [
      filters,
      fetchForUsers,
      company,
      declinedSubstitutes,
      showScore,
      substituteId,
    ]
  )
  return (
    <div className={className}>
      <AsyncSelect
        cacheOptions={true}
        defaultOptions
        onChange={onSubmit}
        defaultValue={defaultValue}
        loadOptions={loadUsers}
        isDisabled={isDisabled}
        isMulti={isMulti}
        controlShouldRenderValue={!!defaultValue?.label}
      />
    </div>
  )
}

export default UserSearch
