import {
  handleError,
  handleSuccess,
  logError,
} from '../../helpers/toastHelpers/toastHandlers'
import * as types from '../constants'
import toastTypes from '../../helpers/toastHelpers/toastTypes'
import { saveFile } from '../../services/files'

const blobMiddleware = () => (next) => (action) => {
  if (action.type === types.PIGEONAI_DOWNLOAD_FILE_SUCCESS) {
    try {
      const { blob } = action.payload

      saveFile(blob, 'instructa.docx')
      handleSuccess(toastTypes.successTypes.PIGEONAI_DOWNLOAD_FILE_SUCCESS)
    } catch (error) {
      logError('Error procesando blob:', error)
      handleError(toastTypes.errorTypes.BLOB_PROCESSING_ERROR)
    }
  }
  return next(action)
}

export default blobMiddleware
