import * as types from '../constants'
import { combineReducers } from 'redux'
import { settings } from './settings'
import { auth } from './auth'
import { users } from './users'
import { expedient } from './expedient'
import { dashboard } from './dashboard'
import { invoice } from './invoice'
import { promotion } from './promotion'
import { userInvoiceAccount } from './userInvoiceAccount'
import { substituteReview } from './substituteReview'
import { judicialBrief } from './judicialBrief'
import { remittance } from './remittance'
import { publicApi } from './publicApi'
import { notary } from './notary'
import { issue } from './issue'
import { projectShortTerm } from './projectShortTerm'
import { capabilities } from './capabilities'
import { procura } from './procura'
import { communityPartners } from './communityPartners'
import { specialPrices } from './specialPrices'
import { projectLongTerm } from './projectLongTerm'
import { possession } from './possession'
import { urgentIssues } from './urgentIssues'
import { pigeonAI } from './pigeonAI'
import { userReminder } from './userReminder'

const appReducer = combineReducers({
  settings,
  auth,
  users,
  expedient,
  dashboard,
  invoice,
  promotion,
  userInvoiceAccount,
  substituteReview,
  judicialBrief,
  remittance,
  publicApi,
  notary,
  issue,
  projectShortTerm,
  projectLongTerm,
  capabilities,
  procura,
  communityPartners,
  specialPrices,
  possession,
  urgentIssues,
  pigeonAI,
  userReminder,
})

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT_SUCCESS) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
