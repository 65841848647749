import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { baseAPI } from '../../../../config'
import moment from 'moment'
import action from '../../../../mixPanelsActions'
import { getPermissionsToDeleteFile } from '../utils/getPermissionsToDeleteFile'

const useFile = (documentType, defaultFiles = [], group, update) => {
  const { activeUser } = useSelector((state) => state.auth)
  const expedient = useSelector((state) => state.expedient.expedient)
  const procura = useSelector((state) => state.procura.procura)
  const projectShortTerm = useSelector(
    (state) => state.projectShortTerm.projectShortTerm
  )
  const projectLongTerm = useSelector(
    (state) => state.projectLongTerm.projectLongTerm
  )

  const [uploadedFiles, setUploadedFiles] = useState(defaultFiles)
  const prevUploadedFilesRef = useRef(defaultFiles)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (
      JSON.stringify(prevUploadedFilesRef.current) !==
      JSON.stringify(uploadedFiles)
    ) {
      prevUploadedFilesRef.current = uploadedFiles
      update(uploadedFiles)
    }
  }, [uploadedFiles, update])

  const filesToShow =
    uploadedFiles.length > 0
      ? uploadedFiles.filter((f) => f.status !== 'deleted')
      : []

  const onDrop = async (files) => {
    if (files.length === 0) return

    files = files.length > 1 ? files : [files[0]]
    setLoading(true)

    const newFiles = await Promise.all(
      files.map(async (file, index) => {
        file['group'] = group
        const newFile = await uploadFile(file, index)
        return newFile
      })
    )

    const validFiles = newFiles.filter((file) => file)
    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles])
    setLoading(false)
  }

  const uploadFile = async (file, index) => {
    let { name, type } = file
    let currentDocument = null

    switch (documentType) {
      case 'expedients':
        currentDocument = expedient
        break
      case 'pigeonProcura':
        currentDocument = procura
        break
      case 'pigeonOnCall':
        currentDocument = projectShortTerm
        break
      case 'pigeonInHouse':
        currentDocument = projectLongTerm
        break
      default:
        currentDocument = null
    }

    name = name.replace(/[#?*+~,()'"!:@]/g, '_')

    const file_details = {
      name,
      type,
      group,
      document_id: currentDocument?._id,
      documentType,
    }

    try {
      const response = await fetch(`${baseAPI}/signed-file`, {
        method: 'POST',
        body: JSON.stringify({ file_details }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      const signedData = await response.json()

      if (signedData.err) throw new Error(signedData.err)

      const { returnData } = signedData.data
      const { url, signedRequest, key, bucket } = returnData

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': type,
        },
        body: file,
      }

      const uploadResult = await fetch(signedRequest, options)

      if (!uploadResult.ok) {
        toast.error(
          'Hubo un problema en la carga, por favor intentelo más tarde',
          { theme: 'colored' }
        )
        setLoading(false)
        return
      }

      toast.success(`Archivo ${index + 1} guardado con éxito`, {
        theme: 'colored',
        autoClose: 500,
      })
      const newFile = {
        url,
        name,
        type,
        status: 'active',
        group,
        key,
        document_id: currentDocument?._id,
        bucket,
        uploaded_date: moment(),
        documentType,
      }

      return newFile
    } catch (_error) {
      toast.error(
        'Hubo un problema en la carga, por favor intentelo más tarde',
        { theme: 'colored' }
      )
      return
    }
  }

  const downloadFile = async (file) => {
    try {
      const response = await fetch(`${baseAPI}/get-signed-file/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(file),
      })

      const res = await response.json()

      if (!res) {
        return toast.error(
          'No se ha podido descargar el archivo, contacte con el administrador',
          { theme: 'colored' }
        )
      }

      const userAgent = window.navigator.userAgent.toLowerCase()
      const link = document.createElement('a')

      if (!/iphone|ipad/i.test(userAgent)) {
        link.setAttribute('target', 'about:blank')
      }

      link.href = res.data.returnData.signedRequest
      link.download = res.data.returnData.file.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (_err) {
      toast.error(
        'No se ha podido descargar el archivo, contacte con el administrador',
        { theme: 'colored' }
      )
    }
  }

  const permissionToDeleteFile = getPermissionsToDeleteFile({
    documentType,
    expedient,
    procura,
    projectShortTerm,
    projectLongTerm,
    activeUser,
  })

  const onDropRejected = () => {
    toast.warn('El archivo es demasiado grande (máx. 50Mb)', {
      theme: 'colored',
    })
    action.identify(activeUser._id)
    action.track('FileRejected', {
      document: document?._id,
      documentType,
    })
  }

  return {
    uploadedFiles,
    loading,
    uploadFile,
    onDrop,
    downloadFile,
    onDropRejected,
    filesToShow,
    permissionToDeleteFile,
  }
}

export default useFile
