import * as types from '../constants'

const defaultState = {
  files: [],
  downloadReady: false,
  loading: false,
  requestId: '',
  hasDownloaded: false,
}

export const pigeonAI = (state = defaultState, action) => {
  console.log(action)

  switch (action.type) {
    case types.PIGEONAI_UPLOAD_FILES_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.PIGEONAI_UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        requestId: action.payload.requestId,
      }

    case types.PIGEONAI_UPLOAD_FILES_ERROR:
      return {
        ...state,
        loading: false,
        ...defaultState,
      }

    case types.PIGEONAI_DOWNLOAD_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.PIGEONAI_DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasDownloaded: true,
      }

    case types.PIGEONAI_DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        loading: false,
      }

    case types.PIGEONAI_GET_STATUS_REQUEST:
      return {
        ...state,
      }

    case types.PIGEONAI_GET_STATUS_SUCCESS:
      return {
        ...state,
        downloadReady: action.payload.downloadReady,
      }

    case types.PIGEONAI_GET_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      }

    case types.RESET_DOWNLOAD_READY:
      return {
        ...state,
        downloadReady: false,
        hasDownloaded: false,
        requestId: '',
      }

    default:
      return state
  }
}
