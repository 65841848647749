export const saveFile = (blobOrUrl, name) => {
  //if blobOrUrl is string
  const url = typeof blobOrUrl === 'string' 
    ? blobOrUrl 
    : window.URL.createObjectURL(blobOrUrl)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.target = '_blank'
  link.download = name
  document.body.appendChild(link)
  link.click()
  window.setTimeout(() => {
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }, 100)
}