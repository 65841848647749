import React from 'react'
import { Spinner } from 'reactstrap'

const Loading = ({ type, loading }) => {
  /*
    types:
      - screen: full screen loading with transparent white bg
      - inPlace: show loading dots in relative position
  */
  type = type || 'screen'

  return (
    <div className={`loading ${type} ${loading ? 'in' : 'hide'}`}>
      <div className="loadingContainer">
        <div className="half-circle-spinner">
          <div className="circle circle-1"></div>
          <div className="circle circle-2"></div>
        </div>
      </div>
    </div>
  )
}

export default Loading
