const errorTypes = {
  DUPLICATE_EMAIL: 'Ya hay un usuario registrado con este email',
  PASSWORD_POLICY:
    'La contraseña no cumple con la política de seguridad: Debe contener caracteres especiales, una mayúscula, una minúscula y un número',
  LOCAL_STORAGE_NOT_FOUND: 'Sesión cerrada, vuelva a iniciar sesión',
  LOCAL_STORAGE_NOT_SETTED:
    'Error de sesión, inténtalo de nuevo o contacte con el administrador',
  LOCAL_STORAGE_NOT_REMOVED:
    'Error de sesión, inténtalo de nuevo o contacte con el administrador',
  CREATE_USER_ERROR: 'No se ha podido crear el usuario, inténtalo de nuevo',
  CREATE_CLIENT_ERROR: 'No se ha podido crear el usuario, inténtalo de nuevo',
  UPDATE_USER_SUCCESS_IMAGE_NOT_VALID:
    'La imagen no es válida, por favor adjunta una nueva',
  UPDATE_USER_ERROR_CIF_USED: 'Ya hay un usuario registrado con este CIF',
  UPDATE_USER_ERROR: 'Usuario no actualizado, inténtelo de nuevo',
  GENERIC_ERROR:
    'Algo ha salido mal, inténtalo de nuevo o contacte con el administrador',
  ADD_SUBSTITUTE_EXCLUDED_ERROR:
    'No se ha podido excluir al sustituto, inténtalo de nuevo',
  REMOVE_SUBSTITUTE_EXCLUDED_ERROR:
    'No se ha podido eliminar al sustituto, inténtalo de nuevo',
  SET_LINKED_PARTNERS_ERROR:
    'No se han podido asociar los partners seleccionados',
  DELETE_LINKED_PARTNER_ERROR: 'No se han podido desvincular el partner',
  ADD_CUSTOM_FIELD_ERROR: 'Solo se permiten 5 campos personalizados activos',
  UPDATE_CUSTOM_FIELD_ERROR: 'Solo se permiten 5 campos personalizados activos',
  CHANGE_PARTNER_TO_COMPANY_ERROR:
    'Ha ocurrido algun error y no se ha realizado el cambio',
  PIGEONAI_UPLOAD_FILES_ERROR: 'Error al subir los archivos',
  PIGEONAI_DOWNLOAD_FILE_ERROR: 'Error al descargar los archivos',
  PIGEONAI_GET_STATUS_ERROR: 'Error al obtener el estado de los archivos',
  FILE_DOWNLOAD_ERROR: 'Error al descargar el archivo',
  BLOB_PROCESSING_ERROR: 'Error al procesar el archivo descargado',
  DELETE_USER_ERROR: 'Error al borrar el usuario',
}

const successTypes = {
  USER_CREATED: 'Usuario creado',
  UPDATE_USER_SUCCESS: 'Usuario actualizado',
  DELETE_USER_SUCCESS: 'Usuario eliminado',
  SUBSTITUTE_EXCLUDED: 'Sustituto excluido',
  SUBSTITUTE_REMOVED: 'Sustituto desbloqueado',
  PARTNER_LINKED: 'Partner vinculado correctamente',
  COMPANY_CHANGE_SUCCESS: 'Cambio de empresa realizado correctamente',
  AUTH_POOL_SUCCESS: 'Se ha realizado el cambio correctamente',
  CREATE_CLIENT_SUCCESS: 'Usuario registrado correctamente',
  ADD_SUBSTITUTE_EXCLUDED_SUCCESS: 'Sustituto excluido',
  REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS: 'Sustituto desbloqueado',
  CHANGE_PARTNER_TO_COMPANY_SUCCESS:
    'Cambio de empresa realizado correctamente',
  ADD_AUTH_POOL_SUCCESS: 'Se ha realizado el cambio correctamente',
  ADD_AUTH_POOL_ERROR: 'Ya existe un pool con el mismo id/nombre',
  EDIT_AUTH_POOL_ERROR:
    'Ha ocurrido algun error y no se ha realizado el cambio',
  ADD_FAVORITE_SUBSTITUTE_SUCCESS: 'Sustituto añadido a favortios',
  REMOVE_FAVORITE_SUBSTITUTE_SUCCESS: 'Sustituto desmarcado como favorito',
  PIGEONAI_DOWNLOAD_FILE_SUCCESS: 'Archivo descargado con éxito',
  CREATE_USER_SUCCESS: 'Usuario creado correctamente',
  PIGEONAI_UPLOAD_FILES_SUCCESS: 'Archivos subido correctamente',
}

const toastTypes = { errorTypes, successTypes }

export default toastTypes
