import Dropzone from 'react-dropzone'
import Loading from '../Loading'
import { Button } from 'reactstrap'
import useFile from './hooks/useFile'

const UploadDocument = ({
  defaultFiles = [],
  documentType = 'expedients',
  multiple = false,
  group,
  update,
  deleteFile,
  disabled,
}) => {
  const {
    downloadFile,
    loading,
    onDrop,
    onDropRejected,
    filesToShow,
    permissionToDeleteFile,
  } = useFile(documentType, defaultFiles, group, update)

  return (
    <>
      <Loading loading={loading} />
      {!disabled && (
        <Dropzone
          onDrop={onDrop}
          onDropRejected={onDropRejected}
          accept="image/jpeg, image/png, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
          minSize={0}
          maxSize={50485760}
          multiple={multiple}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <span className="text-center">
                Arrastra el archivo o click para seleccionar nuevo
              </span>
            </div>
          )}
        </Dropzone>
      )}

      {filesToShow?.length === 0 && (
        <div
          key="no-files"
          className="text-center dropzone border-top-0 mt-0 mb-4"
          style={{ backgroundColor: 'white' }}
        >
          {' '}
          Sin archivos{' '}
        </div>
      )}

      {filesToShow.length > 0 &&
        filesToShow.map((file, index) =>
          typeof file === 'string' ? (
            <li
              key={`${index}-${file}`}
              className="text-left list-group-item list-group-item-success p-0 ps-4"
            >
              <div className="d-flex justify-content-between ">
                <a
                  href={file}
                  className="mt-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.split('___')[1].replaceAll(/[_-]/g, ' ')}
                </a>
                {permissionToDeleteFile && (
                  <span
                    className="btn pointer border-0"
                    style={{ color: 'red' }}
                    onClick={() => deleteFile(file)}
                  >
                    {' '}
                    x{' '}
                  </span>
                )}
              </div>
            </li>
          ) : (
            file.status !== 'deleted' && (
              <li
                key={`${index}-${file}`}
                className="text-left list-group-item list-group-item-success p-0 ps-4"
                style={{ border: '1px dashed grey' }}
              >
                <div className="d-flex justify-content-between ">
                  <>
                    <Button
                      data-toggle="Descargar archivo"
                      data-placement="bottom"
                      title="Descargar archivo"
                      id="downloadButton"
                      color="primary"
                      onClick={() => downloadFile(file)}
                      rel="noopener noreferrer"
                      type="button"
                      style={{
                        color: '#354052',
                        all: 'unset',
                        cursor: 'pointer',
                        marginTop: '2px',
                        marginBottom: '2px',
                      }}
                    >
                      {file.name ? file?.name.replaceAll(/[_-]/g, ' ') : 'file'}
                    </Button>
                  </>
                  {permissionToDeleteFile && (
                    <span
                      className="btn pointer border-0"
                      style={{ color: 'red' }}
                      onClick={() => deleteFile(file)}
                      role="button"
                    >
                      {' '}
                      x{' '}
                    </span>
                  )}
                </div>
              </li>
            )
          )
        )}
    </>
  )
}

export default UploadDocument
